import React, { useEffect } from "react";
import { MdOutlineContactless } from "react-icons/md";
import { SlEvent } from "react-icons/sl";
import AOS from "aos";
import "aos/dist/aos.css";

const Process = [
  {
    id: 1,
    no: "1",
    icon: <SlEvent size={35} />,
    title: "Client Needs and Satisfaction",
    description:
      "we prioritize understanding and addressing client requirements, ensuring their vision and goals are met with high-quality solutions.",
    link: "ecom",
  },
  {
    id: 2,
    no: "2",
    icon: <SlEvent size={35} />,
    title: "Innovation and Technology",
    description:
      "We stay up-to-date with the latest technologies and industry trends. We prioritize adopting innovative approaches and tools to provide cutting-edge solutions and maintain a competitive edge",
    link: "webdes",
  },
  {
    id: 3,
    no: "3",
    icon: <SlEvent size={35} />,
    title: "Deliver result",
    description:
      "We invest in the continuous learning and development of your team. Prioritizing hiring skilled professionals and providing ongoing training to enhance their expertise.",
    link: "webdes",
  },
  {
    id: 4,
    no: "4",
    icon: <SlEvent size={35} />,
    title: "Customer Support and Maintenance",
    description:
      "We provide excellent post-launch support and maintenance services. Prioritize addressing any issues promptly and ensuring the software remains functional and up-to-date.",
    link: "webdes",
  },
];

const WorkProcess = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="w-full bg-[#F5F5F5] py-16">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <div className="flex justify-center items-center font-bold text-[#C32735] text-xl gap-2 mb-4">
            <MdOutlineContactless size={25} />
            <h3>Work Process</h3>
          </div>
          <h1 className="text-3xl font-bold">
            Change how to work into{" "}
            <span className="text-[#C32735]">"our Priorities"</span>
          </h1>
        </div>
        <div
          className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8"
          data-aos="fade-right"
        >
          {Process.map((item) => (
            <div
              key={item.id}
              className="bg-[#FFFFFF] rounded-lg shadow-lg p-6 relative mb-8 group overflow-hidden"
            >
              <div className="absolute top-[-23px] left-[-17px] bg-[#C32735] w-16 h-16 rounded-full text-white text-2xl font-semibold z-20 flex items-center justify-center shadow-lg group-hover:bg-white group-hover:text-black">
                {item.no}
              </div>

              <div className="absolute inset-0 bg-[black] opacity-90 transition-transform duration-700 transform translate-y-full group-hover:translate-y-0 z-0"></div>

              <div className="flex flex-col items-center gap-4 pt-8 relative z-10 group-hover:text-white">
                <div className="text-[#C32735] group-hover:text-white">
                  {item.icon}
                </div>
                <h2 className="text-[17px] font-bold">{item.title}</h2>
                <p className="text-center">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WorkProcess;
