import React, { useState, useRef, useEffect } from "react";
import { FaWhatsapp } from "react-icons/fa";

const App = () => {
  const [chatOpen, setChatOpen] = useState(false);
  const [messages, setMessages] = useState([
    {
      sender: "bot",
      text: "👋 How can I help you? Do you need any information? Please type Yes or No.",
    },
  ]);
  const [currentStage, setCurrentStage] = useState(0);
  const [history, setHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const chatRef = useRef(null); // Reference for auto-scrolling

  // Define options for each stage
  const stages = [
    {
      stage: 0,
      options: [
        { text: "Yes", nextStage: 1 },
        { text: "No", nextStage: 8 },
      ],
    },
    {
      stage: 1,
      text: "Great! Please select one of the following options:",
      options: [
        { text: "1. What services do you offer?", nextStage: 2 },
        { text: "2. How can I contact you?", nextStage: 3 },
        { text: "3. What are your working hours?", nextStage: 4 },
        { text: "4. Are you working on projects?", nextStage: 5 },
        { text: "5. Are you giving any opportunity?", nextStage: 6 },
      ],
    },
    {
      stage: 2,
      text: (
        <>
          <span><strong>1.</strong> We offer web development,</span><br />
          <span><strong>2.</strong> mobile app development,</span><br />
          <span><strong>3.</strong> Android app development,</span><br />
          <span><strong>4.</strong> Flutter / Dart / Firebase,</span><br />
          <span><strong>5.</strong> Android / Java / Kotlin,</span><br />
          <span><strong>6.</strong> MERN & MEAN Stack,</span><br />
          <span><strong>7.</strong> Digital Marketing,</span><br />
          <span><strong>8.</strong> SEO,</span><br />
          <span><strong>9.</strong> Graphic Designing,</span><br />
          <span><strong>10.</strong> UI/UX,</span><br />
          <span><strong>11.</strong> Crypto Trading,</span><br />
          <span><strong>12.</strong> Freelancing and many more.</span><br />
          <span>Do you want more information? Contact Us on +92301-6658497</span>
        </>
      ),
      options: [
        { text: "More on web development", nextStage: 7 },
        { text: "More on mobile app development", nextStage: 8 },
        { text: "Back", nextStage: 1 },
      ],
    },
    {
      stage: 3,
      text: (
        <>
          <span><strong>1.</strong> You can contact us via email at <strong>contact@example.com</strong> or call us at <strong>+92301-6658497</strong>.</span><br />
          <span>Need more information?</span>
        </>
      ),
      options: [
        { text: "Yes", nextStage: 9 },
        { text: "No, back to options", nextStage: 1 },
        { text: "Back", nextStage: 1 },
      ],
    },
    {
      stage: 4,
      text: (
        <>
          <span><strong>1.</strong> Our working hours are:</span><br />
          <span><strong>- Morning Sessions:</strong> 9 AM to 6 PM</span><br />
          <span><strong>- Evening Sessions:</strong> 7 PM to 3 AM</span><br />
          <span>Monday to Friday. Would you like to know more?</span>
        </>
      ),
      options: [
        { text: "Yes", nextStage: 9 },
        { text: "No, back to options", nextStage: 1 },
        { text: "Back", nextStage: 1 },
      ],
    },
    {
      stage: 5,
      text: (
        <>
          <span><strong>1.</strong> Yes, we are working on various projects.</span><br />
          <span>Would you like to hear more about them?</span>
        </>
      ),
      options: [
        { text: "Yes", nextStage: 9 },
        { text: "No", nextStage: 1 },
        { text: "Back", nextStage: 1 },
      ],
    },
    {
      stage: 6,
      text: (
        <>
          <span><strong>1.</strong> Yes, we provide various opportunities.</span><br />
          <span>Would you like to know more?</span>
        </>
      ),
      options: [
        { text: "Yes", nextStage: 9 },
        { text: "No", nextStage: 1 },
        { text: "Back", nextStage: 1 },
      ],
    },
    {
      stage: 7,
      text: (
        <>
          <span><strong>1.</strong> We provide various web development services including:</span><br />
          <span><strong>- e-commerce,</strong></span><br />
          <span><strong>- CMS,</strong></span><br />
          <span><strong>- and more.</strong></span>
        </>
      ),
      options: [{ text: "Back", nextStage: 2 }],
    },
    {
      stage: 8,
      text: (
        <>
          <span><strong>1.</strong> Our mobile app development services cover:</span><br />
          <span><strong>- iOS</strong></span><br />
          <span><strong>- Android platforms.</strong></span>
        </>
      ),
      options: [{ text: "Back", nextStage: 2 }],
    },
    {
      stage: 9,
      text: (
        <>
          <span><strong>1.</strong> Thank you!</span><br />
          <span>If you have any other questions, feel free to ask.</span>
        </>
      ),
      options: [{ text: "Back", nextStage: 0 }],
    },
    {
      stage: 10,
      text: (
        <>
          <span><strong>1.</strong> Alright!</span><br />
          <span>If you need anything else, just let me know.</span>
        </>
      ),
      options: [{ text: "Start Over", nextStage: 0 }],
    },
  ];
  

  // Handle user selection and move to the next stage
  const handleOptionClick = (option) => {
    setHistory([...history, currentStage]);
    setMessages((prevMessages) => [
      ...prevMessages,
      { sender: "user", text: option.text },
    ]);
    simulateBotResponse(
      stages[option.nextStage]?.text || "Please choose an option:",
      option.nextStage
    );
  };

  // Simulate bot response with typing delay
  const simulateBotResponse = (responseText, nextStage) => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setCurrentStage(nextStage);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "bot", text: responseText },
      ]);
    }, 1500); // Simulating typing delay
  };

  // Handle "Back" button click
  const handleBackClick = () => {
    if (history.length > 0) {
      const prevStage = history[history.length - 1];
      setHistory(history.slice(0, -1));
      simulateBotResponse(
        stages[prevStage]?.text || "Please choose an option:",
        prevStage
      );
    }
  };

  // Open WhatsApp on button click
  const handleWhatsAppClick = () => {
    const phoneNumber = "+923016658497";
    const message = "Hello, I would like to chat!";
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(url, "_blank");
  };

  // Toggle chat window visibility
  const toggleChat = () => {
    setChatOpen(!chatOpen);
  };

  // Auto-scroll to the bottom of the chat
  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className="fixed bottom-4 right-4 z-50">
      {/* WhatsApp Icon Button */}
      <div
        className="bg-green-500 text-white p-3 rounded-full shadow-lg cursor-pointer hover:bg-green-600 transition-all"
        onClick={toggleChat}
      >
        <FaWhatsapp size={45} />
      </div>

      {/* Chatbot Window */}
      {chatOpen && (
        <div className="bg-white border border-gray-300 rounded-lg shadow-lg w-80 h-auto max-h-96 p-2 absolute bottom-16 right-0 flex flex-col">
          {/* Header Section */}
          <div className="bg-gray-100 text-center p-2 border-b">
            <h2 className="text-sm font-semibold">
              You are chatting with HR Codings First
            </h2>
          </div>

          <div className="flex-1 overflow-y-auto" ref={chatRef}>
            <div className="flex flex-col space-y-2">
              {/* Displaying chat messages */}
              {messages.map((msg, index) => (
                <div
                  key={index}
                  className={`p-2 rounded-lg max-w-xs ${
                    msg.sender === "bot"
                      ? "bg-gray-200 self-start"
                      : "bg-green-200 self-end"
                  }`}
                  style={{ fontSize: "0.75rem" }} // Adjusted font size here
                >
                  {msg.text}
                </div>
              ))}

              {/* Typing Indicator */}
              {isLoading && (
                <div className="flex items-center">
                  <div className="loader"></div>
                  <span
                    className="text-gray-500"
                    style={{ fontSize: "0.75rem" }}
                  >
                    Typing...
                  </span>{" "}
                  {/* Adjusted font size here */}
                </div>
              )}
            </div>
          </div>

          {/* Options Area */}
          <div className="mt-2 border-t pt-1">
            {/* Display initial options for the current stage */}
            {currentStage === 0 && (
              <>
                <p
                  className="text-sm text-gray-700"
                  style={{ fontSize: "0.75rem" }}
                >
                  Do you need any information?
                </p>{" "}
                {/* Adjusted font size here */}
                {stages[currentStage].options.map((option, index) => (
                  <button
                    key={index}
                    className="bg-blue-500 text-white p-2 rounded-lg mb-1 hover:bg-blue-600 transition-all w-full text-sm" // Smaller button size and text
                    onClick={() => handleOptionClick(option)}
                  >
                    {option.text}
                  </button>
                ))}
              </>
            )}

            {/* Show options when user selects Yes */}
            {currentStage === 1 && (
              <>
                <p
                  className="text-sm text-gray-700"
                  style={{ fontSize: "0.75rem" }}
                >
                  {stages[currentStage].text}
                </p>{" "}
                {/* Adjusted font size here */}
                <div className="mt-1">
                  {stages[currentStage].options.map((option, index) => (
                    <button
                      key={index}
                      className="bg-blue-500 text-white p-2 rounded-lg mb-1 hover:bg-blue-600 transition-all w-full text-sm" // Smaller button size and text
                      onClick={() => handleOptionClick(option)}
                    >
                      {option.text}
                    </button>
                  ))}
                </div>
              </>
            )}

            {/* Show back option for all other stages */}
            {currentStage !== 0 && currentStage !== 1 && (
              <button
                className="bg-red-500 text-white p-2 rounded-lg mt-1 hover:bg-red-600 transition-all w-full text-sm" // Smaller button size and text
                onClick={handleBackClick}
              >
                Back
              </button>
            )}
          </div>

          {/* WhatsApp Chat Button */}
          <button
            className="w-full bg-green-500 text-white p-2 rounded-lg hover:bg-green-600 transition-all mt-2 text-sm" // Smaller button size and text
            onClick={handleWhatsAppClick}
          >
            Start WhatsApp Chat
          </button>
        </div>
      )}

      <style jsx>{`
        .loader {
          border: 4px solid #f3f3f3; /* Light gray */
          border-top: 4px solid #3498db; /* Blue */
          border-radius: 50%;
          width: 10px;
          height: 10px;
          animation: spin 1s linear infinite;
          margin-right: 8px;
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </div>
  );
};

export default App;
